import React from "react"
import { Link } from "gatsby"
import {Category} from "../utils/model"

const defaultProps = {
    linkText: `View the Breadcrumb`,
}

type BreadcrumbProps = { category: Category } & typeof defaultProps

const Breadcrumb = ({ category }: BreadcrumbProps) => {
    
    let cats = category.ancestors()
    cats.reverse().shift();
    cats.push(category)

    return (
        <div className="flex flex-wrap justify-start text-2xs lg:text-xs pr-1 mb-4">
            { 
                cats.map(ancestor => (
                    <Link key={ancestor.id} to={ancestor.slug} className="capitalize-first-letter font-sans bg-white mr-1 flex-initial rounded border border-gray-200 text-primary px-3 py-1 no-underline mb-1">
                        {ancestor.name}
                    </Link>
                ))
            }
        
        </div>
    )
}
    
export default Breadcrumb
    
Breadcrumb.defaultProps = defaultProps
    