import React from "react"
import {Organization} from "../utils/model"
import { Link } from "gatsby";

type LeversCardProps = {
    organization: Organization
    className: string
}

const LeversCard = ({ organization, className }: LeversCardProps) => {

    return (
        <div className={"text-primary relative  " + className}>
            <h2 className="text-xl font-bold mb-2 whitespace-no-wrap">Leviers d'impact</h2>
            <ul className="my-4">
                {organization.levers.map(l => (
                    <li key={l.id} className="flex font-sans text-sm font-bold my-2 flex items-center">
                        <img className="mr-2"  src={l.pictoURL} />
                        {/* <span className="whitespace-no-wrap">{l.name}</span> */}
                        <span className="">{l.name}</span>
                    </li>
                ))}
            </ul>

            <Link className="inline-block text-primary text-sm font-bold underline font-sans" to={"/philosophie#les-leviers"} style={{textDecorationColor: "#66D3B9"}}>
                Tout sur les leviers
            </Link>
        </div>
    )
}

export default LeversCard
